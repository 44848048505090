<template>
  <v-btn text @click="$vuetify.goTo(0)">
    {{ $vuetify.lang.t('$vuetify.general.backToTop') }}
    <v-icon right>mdi-chevron-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'KurccBackToTop'
}
</script>
